import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useToast } from '@chakra-ui/react';
import { isValidCNPJ, isValidCPF } from '@waygee/common';
import { AccountTypes, ISignupTokenDto } from '@waygee/shared-types';
import { useFormik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';
import { ApiNames, getRequestConfig } from '../../../common/apis/ApiRoutes';
import appConfig from '../../../common/configuration/AppConfig';
import {
  ChakraColors,
  colors,
} from '../../../common/configuration/ChakraColors';
import { axiosCustomInstance } from '../../../common/network';
import { AuthToken } from '../../../common/types/auth';
import { getQueryParam } from '../../../common/utility/navigation';
import { SignUpStepperProps } from '../components/SignUpStepper';
import { SignUpSchema, SignUpStep } from '../types/signUpSchema';
import '../SignUp.language';

const useSignUp = () => {
  const { disableSignup } = appConfig;
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBusiness, setIsBusiness] = useState<boolean | null>(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const { t } = useTranslation('signup');
  const toast = useToast();
  const leadToken = getQueryParam('token');
  const source = getQueryParam('source');
  const [isLeadGenerated, setIsLeadGenerated] = useState<boolean>(false);
  const [authorizedToken, setAuthorizedToken] = useState<string>('');

  const [signUpStepperProps, setSignUpStepperProps] =
    useState<SignUpStepperProps>({
      steps: [
        { title: t('Start'), description: t('E-mail confirmation') },
        { title: t('Identify'), description: t('Personal Data') },
        { title: t('Additional Data'), description: t('Address and other') },
        { title: t('Access data'), description: t('Login and password') },
        { title: t('End'), description: '' },
      ],
      activeStepIndex: 0,
    });

  const validationSchema = Yup.object<SignUpSchema>({
    leadEmail: Yup.string()
      .email(t('Invalid email address'))
      .required(t('Email is required')),
    individualStreet: Yup.string().when('step', {
      is: 'addressData',
      then: (schema) =>
        schema
          .required(t('Address street is required'))
          .min(3, t('Must be 3 characters or more'))
          .max(100, t('Must be 100 characters or less')),
      otherwise: (schema) => schema.notRequired(),
    }),
    individualCity: Yup.string().when('step', {
      is: 'addressData',
      then: (schema) =>
        schema
          .required(t('Address city is required'))
          .min(3, t('Must be 3 characters or more'))
          .max(100, t('Must be 100 characters or less')),
      otherwise: (schema) => schema.notRequired(),
    }),
    individualSubdivision: Yup.string().when('step', {
      is: 'addressData',
      then: (schema) =>
        schema
          .required(t('Address state is required'))
          .min(2, t('Must be 2 characters or more'))
          .max(2, t('Must be 2 characters or less')),
      otherwise: (schema) => schema.notRequired(),
    }),
    individualPostalCode: Yup.string().when('step', {
      is: 'addressData',
      then: (schema) =>
        schema
          .required(t('Address zip code is required'))
          .min(9, t('Must be 9 characters or more'))
          .max(9, t('Must be 9 characters or less')),
      otherwise: (schema) => schema.notRequired(),
    }),
    businessCnpj: Yup.string().when('step', {
      is: 'businessData',
      then: (schema) =>
        schema
          .required(t('CNPJ is required'))
          .test('cpf', 'CNPJ invalido', function (value) {
            const { path, createError } = this;
            if (value === undefined) {
              return true;
            }
            const isValid = isValidCNPJ(value);
            return isValid || createError({ path, message: 'CNPJ invalido' });
          })
          .trim(),
      otherwise: (schema) => schema.notRequired(),
    }),
    businessLegalName: Yup.string().when('step', {
      is: 'businessData',
      then: (schema) =>
        schema
          .required(t('Business name is required'))
          .min(5, t('Must at last 5 characters'))
          .max(100, t('Must be 100 characters or less')),
      otherwise: (schema) => schema.notRequired(),
    }),
    email: Yup.string().when('step', {
      is: 'userData',
      then: (schema) =>
        schema
          .required(t('Email is required'))
          .email(t('Invalid email address'))
          .max(100, t('Must be 100 characters or less')),
      otherwise: (schema) => schema.notRequired(),
    }),
    password: Yup.string().when('step', {
      is: 'userData',
      then: (schema) =>
        schema
          .required(t('Password is required'))
          .min(8, t('Must be 8 characters or more'))
          .max(16, t('Must be 16 characters or less')),
      otherwise: (schema) => schema.notRequired(),
    }),
    individualLegalName: Yup.string().when('step', {
      is: 'individualData',
      then: (schema) =>
        schema
          .trim()
          .min(3, t('Must be 2 characters or more'))
          .max(100, t('Must be 100 characters or less'))
          .required(t('First name is required')),
      otherwise: (schema) => schema.notRequired(),
    }),
    individualPhone: Yup.string().when('step', {
      is: 'individualData',
      then: (schema) =>
        schema
          .trim()
          .min(15, t('Invalid phone number'))
          .required(t('Phone number is required')),
      otherwise: (schema) => schema.notRequired(),
    }),
    individualCpf: Yup.string().when('step', {
      is: 'individualData',
      then: (schema) =>
        schema
          .trim()
          .test('cpf', 'CPF invalido', function (value) {
            const { path, createError } = this;
            if (value === undefined) {
              return true;
            }
            const isValid = isValidCPF(value);
            return isValid || createError({ path, message: 'CPF invalido' });
          })
          .min(8, t('Must be 8 characters or more'))
          .max(16, t('Must be 8 characters or less'))
          .required(t('Document number is required')),
      otherwise: (schema) => schema.notRequired(),
    }),
    individualDateOfBirth: Yup.date().when('step', {
      is: 'individualData',
      then: (schema) => schema.required(t('Birth date is required')),
      otherwise: (schema) => schema.notRequired(),
    }),
  });
  const formik = useFormik<Partial<SignUpSchema>>({
    initialValues: {},
    validationSchema,
    onSubmit: (values) => {
      if (!isLoading) {
        setIsLoading(true);
        const promise = axiosCustomInstance
          .request<AuthToken>(
            getRequestConfig({
              token: {
                accessToken: authorizedToken,
                refreshToken: '',
              },
              endpoint: ApiNames.SIGN_UP,
              data: {
                email: values.email,
                password: values.password,
                type: isBusiness
                  ? AccountTypes.BUSINESS
                  : AccountTypes.INDIVIDUAL,
                individual: {
                  cpf: values.individualCpf,
                  email: values.email,
                  legalName: values.individualLegalName,
                  phone: values.individualPhone,
                  dateOfBirth: values.individualDateOfBirth,
                  address: {
                    street: values.individualStreet,
                    city: values.individualCity,
                    subdivision: values.individualSubdivision,
                    postalCode: values?.individualPostalCode?.replace(
                      /\D/g,
                      ''
                    ),
                    countryCode: 'BR',
                  },
                },
                business: {
                  cnpj: values.businessCnpj,
                  email: values.email,
                  legalName: values.individualLegalName,
                  phone: values.individualPhone,
                  dateOfBirth: values.individualDateOfBirth,
                  address: {
                    street: values.individualStreet,
                    city: values.individualCity,
                    subdivision: values.individualSubdivision,
                    postalCode: values?.individualPostalCode?.replace(
                      /\D/g,
                      ''
                    ),
                    countryCode: 'BR',
                  },
                },
              },
            })
          )
          .then(() => {
            setFieldValue('step', 'complete');
          })
          .catch(() => {
            throw new Error('Something went wrong');
          })
          .finally(() => setIsLoading(false));

        toast.promise(promise, {
          success: {
            title: t('Account created successful'),
            description: t('Please log-in to continue'),
            isClosable: true,
          },
          error: {
            title: t('Account creation failed'),
            description: t('Something wrong'),
            isClosable: true,
          },
          loading: {
            title: t('Creating account'),
            description: t('Please wait...'),
            isClosable: true,
          },
        });
      }
    },
  });

  const {
    values,
    getFieldProps,
    touched,
    errors,
    handleSubmit,
    validateForm,
    setTouched,
    setErrors,
    setFieldValue,
    isValid,
  } = formik;

  const signUpStep = values.step as SignUpStep;
  const [showBgImage] = useMediaQuery('(min-width: 1000px)');

  const handleLeadSubmit = () => {
    if (!isLoading) {
      setIsLoading(true);
      const metadata = source ? { metadata: { source } } : {};
      const promise = axiosCustomInstance
        .request<AuthToken>(
          getRequestConfig({
            endpoint: ApiNames.LEAD,
            data: {
              email: values.leadEmail,
              ...metadata,
            },
          })
        )
        .then(() => setIsLeadGenerated(true))
        .finally(() => setIsLoading(false));
      toast.promise(promise, {
        success: {
          title: t('E-mail registered'),
          description: t('Please check your inbox to continue'),
          isClosable: true,
        },
        error: {
          title: t('Account creation failed'),
          description: t('Something wrong'),
          isClosable: true,
        },
        loading: {
          title: t('Creating account'),
          description: t('Please wait...'),
          isClosable: true,
        },
      });
    }
  };

  const handleBackStepClick = () => {
    switch (signUpStep) {
      case 'businessData':
        setFieldValue('step', 'individualData');
        break;
      case 'addressData':
        setFieldValue('step', isBusiness ? 'businessData' : 'individualData');
        break;
      case 'userData':
        setFieldValue('step', 'addressData');
        break;
      default:
        break;
    }
  };

  const handleNextStepClick = async () => {
    const validation = await validateForm();
    const touchedFields: { [key: string]: boolean } = {};

    const validationKeys = Object.keys(validation);
    validationKeys.forEach((field) => {
      touchedFields[field] = true;
    });
    setTouched(touchedFields);
    setErrors(validation);
    const isValid = validationKeys.length === 0;
    if (isValid) {
      switch (signUpStep) {
        case 'individualData':
          setFieldValue('step', isBusiness ? 'businessData' : 'addressData');
          break;
        case 'businessData':
          setFieldValue('step', 'addressData');
          break;
        case 'addressData':
          setFieldValue('step', 'userData');
          break;
        default:
          break;
      }
    }
  };

  const validateSignUpToken = useCallback(() => {
    if (leadToken) {
      axiosCustomInstance
        .request<ISignupTokenDto>(
          getRequestConfig({
            endpoint: ApiNames.SIGN_UP_TOKEN,
            token: {
              accessToken: leadToken,
              refreshToken: '',
            },
          })
        )
        .then((result) => {
          setAuthorizedToken(result?.data?.signupToken ?? '');
          setFieldValue('email', result?.data?.email);
          setFieldValue('leadEmail', result?.data?.email);
        })
        .catch(() => {
          setFieldValue('step', 'leadData');
          toast({
            title: t('Token invalid or expired'),
            description: t('Please, register again'),
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
        });
    }
  }, [leadToken, setFieldValue, t, toast]);

  const debouncedFetch = _.debounce((postalCode) => {
    axiosCustomInstance
      .get(`https://cep.awesomeapi.com.br/json/${postalCode}`)
      .then((response) => {
        // Handle the data from the API
        const data = response.data;
        setFieldValue('individualStreet', `${data.address} ${data.district}`);
        setFieldValue('individualCity', data.city);
        setFieldValue('individualSubdivision', data.state);
      })
      .catch((error) => {
        // Handle the error
      });
  }, 1000); // 1000ms delay

  const fetchPostalCode = useCallback(
    (postalCode: string) => {
      // Call the debounced function
      debouncedFetch(postalCode);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  ); // No dependencies

  const getStepperIndex = (step: SignUpStep) => {
    switch (step) {
      case 'leadData':
        return 0;
      case 'individualData':
        return 1;
      case 'businessData':
        return 1;
      case 'addressData':
        return 2;
      case 'userData':
        return 3;
      case 'complete':
        return 4;
      default:
        return 0;
    }
  };

  useEffect(() => {
    if (values.individualPostalCode) {
      fetchPostalCode(values.individualPostalCode);
    }
  }, [fetchPostalCode, values.individualPostalCode]);

  useEffect(() => {
    validateSignUpToken();
  }, [leadToken, validateSignUpToken]);

  useEffect(() => {
    setFieldValue('step', leadToken ? 'individualData' : 'leadData');
  }, [isBusiness, leadToken, setFieldValue]);

  useEffect(() => {
    setIsSubmitDisabled(!isValid);
  }, [isValid]);

  useEffect(() => {
    setSignUpStepperProps((prevState) => ({
      ...prevState,
      activeStepIndex: getStepperIndex(signUpStep),
    }));
  }, [signUpStep, t, values.step]);

  useEffect(() => {
    // Set the background color when the component mounts
    const originalBgColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = colors.primary['900'];
    // Reset the background color when the component unmounts
    return () => {
      document.body.style.backgroundColor = originalBgColor;
    };
  }, []);

  return {
    values,
    getFieldProps,
    touched,
    errors,
    handleSubmit,
    t,
    isLoading,
    showPassword,
    setShowPassword,
    isBusiness,
    setIsBusiness,
    handleNextStepClick,
    signUpStep,
    handleBackStepClick,
    isSubmitDisabled,
    handleLeadSubmit,
    isLeadGenerated,
    signUpStepperProps,
    disableSignup,
    showBgImage,
  };
};

export default useSignUp;
