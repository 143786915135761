import { extendTheme } from '@chakra-ui/react';
//#131856
export const colors = {
  black: '#0c1015',
  gray: {
    '50': '#f9fafa',
    '100': '#f1f1f2',
    '200': '#e6e7e9',
    '300': '#d2d4d7',
    '400': '#a9adb2',
    '500': '#797f88',
    '600': '#4d5560',
    '700': '#2e3744',
    '800': '#19202b',
    '900': '#141a23',
  },
  blue: {
    '50': '#f5f6f7',
    '100': '#dbdee3',
    '200': '#c1c6ce',
    '300': '#a4acb8',
    '400': '#8a94a4',
    '500': '#738092',
    '600': '#5d6b80',
    '700': '#41526b',
    '800': '#31435e',
    '900': '#233653',
  },
  purple: {
    '50': '#f7f7f8',
    '100': '#e1dde5',
    '200': '#cac5d2',
    '300': '#aba2b6',
    '400': '#958aa3',
    '500': '#7a6c8c',
    '600': '#68587c',
    '700': '#57466e',
    '800': '#4a3662',
    '900': '#392454',
  },
  pink: {
    '50': '#f9f7f8',
    '100': '#e6dfe2',
    '200': '#d2c6cb',
    '300': '#b9a5ad',
    '400': '#a78e98',
    '500': '#91727e',
    '600': '#825f6d',
    '700': '#714a59',
    '800': '#603446',
    '900': '#502033',
  },
  red: {
    '50': '#f8f7f7',
    '100': '#e5dfde',
    '200': '#cfc3c2',
    '300': '#b4a2a0',
    '400': '#a68f8d',
    '500': '#927774',
    '600': '#81625e',
    '700': '#6f4b47',
    '800': '#643d39',
    '900': '#522722',
  },
  orange: {
    '50': '#fbfafa',
    '100': '#efedea',
    '200': '#dcd8d2',
    '300': '#c2bbb1',
    '400': '#aaa193',
    '500': '#968a78',
    '600': '#82745f',
    '700': '#6c5b43',
    '800': '#59472b',
    '900': '#4d391b',
  },
  yellow: {
    '50': '#fefefe',
    '100': '#f9f9f7',
    '200': '#edede8',
    '300': '#e0dfd7',
    '400': '#cdccbf',
    '500': '#aaa993',
    '600': '#898769',
    '700': '#6c6a44',
    '800': '#535023',
    '900': '#454217',
  },
  green: {
    '50': '#fafbfb',
    '100': '#e7ecea',
    '200': '#cdd8d4',
    '300': '#b1c2bb',
    '400': '#94aca2',
    '500': '#789589',
    '600': '#5a7e6f',
    '700': '#396453',
    '800': '#245440',
    '900': '#174533',
  },
  teal: {
    '50': '#f9fafa',
    '100': '#e4e9ea',
    '200': '#cdd7d8',
    '300': '#b1c1c2',
    '400': '#8fa5a8',
    '500': '#728e91',
    '600': '#537579',
    '700': '#355c61',
    '800': '#234e53',
    '900': '#174145',
  },
  cyan: {
    '50': '#f9fafa',
    '100': '#e6eaeb',
    '200': '#dae0e2',
    '300': '#ced6d9',
    '400': '#a9b7bc',
    '500': '#98a9ae',
    '600': '#8599a0',
    '700': '#677f88',
    '800': '#4d6a74',
    '900': '#32535e',
  },
  primary: {
    '50': '#f7f7f8',
    '100': '#dde0e5',
    '200': '#c1c6ce',
    '300': '#9ea6b3',
    '400': '#8b95a4',
    '500': '#727e90',
    '600': '#5c6a80',
    '700': '#45556e',
    '800': '#374862',
    '900': '#203451',
  },
};

const defaultProps = {
  focusBorderColor: 'primary.600',
};

export const theme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: '"Roboto", sans-serif',
        backgroundColor: 'primary.50',
      },
      '*::-webkit-scrollbar-track': {
        background: 'primary.900',
      },
      '*::-webkit-scrollbar': {
        width: '10px',
      },
      '*::-webkit-scrollbar-button, *::-webkit-scrollbar-thumb': {
        backgroundColor: 'primary.800',
      },
    },
  },
  colors,
  config: { initialColorMode: 'light', useSystemColorMode: false },
  components: {
    PinInput: {
      defaultProps,
    },
    Textarea: {
      defaultProps,
    },
    Input: {
      defaultProps,
    },
    Select: {
      defaultProps,
    },
  },
});

export const DefaultInvoicePalletColor = [
  '#9900EF',
  '#FF0080',
  '#FF0000',
  '#FF6600',
  '#FFD100',
  '#8FC31F',
  '#00B050',
  '#00B0F0',
  '#0070C0',
  '#002060',
  '#7030A0',
  '#000000',
];

export enum ChakraColors {
  success = 'green.500',
  error = 'red.500',
  warning = 'yellow.500',
  info = 'blue.400',
  hover = 'blue.500',
  primary = 'gray.500',
  secondary = 'gray.400',
  disabled = 'gray.100',
  text = 'gray.500',
  waygee = 'primary.500',
  waygee_light = 'primary.50',
  waygee_dark = 'primary.900',
}
