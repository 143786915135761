import {
  ArrowBackIcon,
  ArrowForwardIcon,
  CheckIcon,
  Icon,
} from '@chakra-ui/icons';
import {
  Flex,
  Box,
  Stack,
  Button,
  Heading,
  Text,
  Link,
  Center,
  Collapse,
  Divider,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';
import { FcBusinessman, FcDepartment } from 'react-icons/fc';
import { ChakraColors, colors } from '../../common/configuration/ChakraColors';
import {
  LeadData,
  SignUpAddressData,
  SignUpBusinessData,
  SignUpComplete,
  SignUpIndividualData,
  SignUpUserData,
} from './components';
import { SignUpStepper } from './components/SignUpStepper';
import useSignUp from './hooks/useSignUp';
import useSignUpStyles from './hooks/useSignUpStyles';
import './SignUp.language';

const SignUp = () => {
  const {
    getFieldProps,
    touched,
    handleSubmit,
    errors,
    t,
    isSubmitDisabled,
    setShowPassword,
    showPassword,
    isBusiness,
    setIsBusiness,
    signUpStep,
    handleNextStepClick,
    handleBackStepClick,
    handleLeadSubmit,
    isLoading,
    isLeadGenerated,
    signUpStepperProps,
    disableSignup,
    showBgImage,
  } = useSignUp();

  const { flexFieldProps, flexMainContainerProps, boxProps, boxPropsLead } =
    useSignUpStyles({
      isBusiness,
    });

  return (
    <Flex
      flexDir={{ base: 'column', md: 'row' }}
      justifyContent="space-around"
      bgColor={ChakraColors.waygee_dark}
      height={{ base: '100%', md: '1000px' }}
      style={{
        backgroundImage:
          showBgImage && signUpStep === 'leadData'
            ? 'url(/assets/images/login-bg-2.png)'
            : 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), url(/assets/images/login-bg-2.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'initial',
      }}
    >
      {signUpStep === 'leadData' && <Flex></Flex>}
      <Flex {...flexMainContainerProps}>
        <Stack
          spacing={8}
          mx={'auto'}
          py={12}
          px={6}
          marginBottom={{ base: '120px' }}
        >
          <Flex
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            gap="20px"
          >
            <img src="assets/images/logo.png" width="350px" alt="Waygee" />
            <Divider />

            {signUpStep === 'leadData' && (
              <Stack align={'center'}>
                <Heading fontSize={'4xl'} color="primary.100">
                  {t('Sign Up')}
                </Heading>
              </Stack>
            )}
          </Flex>
          {signUpStep === 'leadData' && (
            <LeadData
              boxPropsLead={boxPropsLead}
              isLeadGenerated={isLeadGenerated}
              errors={errors}
              getFieldProps={getFieldProps}
              handleLeadSubmit={handleLeadSubmit}
              isLoading={isLoading}
              isSubmitDisabled={isSubmitDisabled}
              touched={touched}
              disableSignup={disableSignup}
            />
          )}
          {signUpStep !== 'leadData' && (
            <>
              <SignUpStepper {...signUpStepperProps} />
              <Collapse
                in={isBusiness === null && signUpStep !== 'complete'}
                animateOpacity
              >
                <Box {...boxProps}>
                  <Flex
                    justifyContent="center"
                    flexDirection="column"
                    wrap="wrap"
                  >
                    <Center>
                      <Text>{t('For who are you opening this account?')}</Text>
                    </Center>
                    <Flex
                      direction="row"
                      gap="20px"
                      justifyContent="center"
                      margin="20px"
                      wrap="wrap"
                    >
                      <Button
                        h={20}
                        onClick={() => setIsBusiness(false)}
                        colorScheme="primary"
                      >
                        <Icon as={FcBusinessman} w={10} h={10} />{' '}
                        {t('For me as individual')}
                      </Button>
                      <Button
                        h={20}
                        onClick={() => setIsBusiness(true)}
                        colorScheme="primary"
                      >
                        <Icon as={FcDepartment} w={10} h={10} />{' '}
                        {t('For me as business')}
                      </Button>
                    </Flex>

                    <Stack pt={6}>
                      <Text align={'center'}>
                        {t('Already a user?')}{' '}
                        <Link color={'blue.400'} href="app/login">
                          {t('Sign in')}
                        </Link>
                      </Text>
                    </Stack>
                  </Flex>
                </Box>
              </Collapse>
              <Collapse
                in={isBusiness !== null && signUpStep !== 'complete'}
                animateOpacity
                delay={2}
              >
                <Box {...boxProps}>
                  <Stack>
                    <Flex gap="20px" justifyContent="center" wrap="wrap">
                      <Button
                        h={8}
                        onClick={() => setIsBusiness(false)}
                        colorScheme={!isBusiness ? 'green' : 'gray'}
                      >
                        <Icon as={FcBusinessman} w={5} h={5} />{' '}
                        {t('For me as individual')}
                      </Button>
                      <Button
                        h={8}
                        onClick={() => setIsBusiness(true)}
                        colorScheme={isBusiness ? 'green' : 'gray'}
                      >
                        <Icon as={FcDepartment} w={5} h={5} />{' '}
                        {t('For me as business')}
                      </Button>
                    </Flex>
                    <Divider />
                    <form onSubmit={handleSubmit}>
                      <Collapse
                        in={signUpStep === 'individualData'}
                        animateOpacity
                        delay={2}
                      >
                        {!isBusiness ? (
                          <Alert
                            status="warning"
                            variant="subtle"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            textAlign="center"
                            height="200px"
                          >
                            <AlertIcon boxSize="40px" mr={0} />
                            <AlertTitle mt={4} mb={1} fontSize="lg">
                              {t('Coming soon')}
                            </AlertTitle>
                            <AlertDescription maxWidth="sm">
                              {t(
                                'Currently we are only accepting business accounts. If you have a business, please select the business option.'
                              )}
                            </AlertDescription>
                          </Alert>
                        ) : (
                          <SignUpIndividualData
                            flexFieldProps={flexFieldProps}
                            errors={errors}
                            getFieldProps={getFieldProps}
                            touched={touched}
                          />
                        )}
                      </Collapse>
                      <Collapse
                        in={signUpStep === 'businessData'}
                        animateOpacity
                        delay={2}
                      >
                        <SignUpBusinessData
                          errors={errors}
                          flexFieldProps={flexFieldProps}
                          getFieldProps={getFieldProps}
                          touched={touched}
                        />
                      </Collapse>
                      <Collapse
                        in={signUpStep === 'addressData'}
                        animateOpacity
                        delay={2}
                      >
                        <SignUpAddressData
                          errors={errors}
                          flexFieldProps={flexFieldProps}
                          getFieldProps={getFieldProps}
                          touched={touched}
                        />
                      </Collapse>
                      <Collapse
                        in={signUpStep === 'userData'}
                        animateOpacity
                        delay={2}
                      >
                        <SignUpUserData
                          errors={errors}
                          flexFieldProps={flexFieldProps}
                          getFieldProps={getFieldProps}
                          setShowPassword={setShowPassword}
                          showPassword={showPassword}
                          touched={touched}
                        />
                      </Collapse>

                      <Stack spacing={10} pt={2}>
                        <Collapse
                          in={[
                            'userData',
                            'businessData',
                            'addressData',
                            'individualData',
                          ].includes(signUpStep)}
                          animateOpacity
                          delay={2}
                        >
                          <Flex justifyContent="flex-end" gap="30px">
                            {signUpStep !== 'individualData' && (
                              <Button
                                rightIcon={<ArrowBackIcon />}
                                onClick={handleBackStepClick}
                              >
                                {t('Back')}
                              </Button>
                            )}

                            {signUpStep !== 'userData' && (
                              <Button
                                rightIcon={<ArrowForwardIcon />}
                                colorScheme="green"
                                onClick={handleNextStepClick}
                              >
                                {t('Next')}
                              </Button>
                            )}

                            {signUpStep === 'userData' && (
                              <Button
                                rightIcon={<CheckIcon />}
                                onClick={() => handleSubmit()}
                                loadingText="Submitting"
                                colorScheme="green"
                                isDisabled={isSubmitDisabled || isLoading}
                              >
                                {t('Sign Up')}
                              </Button>
                            )}
                          </Flex>
                        </Collapse>
                      </Stack>
                    </form>
                  </Stack>
                </Box>
              </Collapse>
              {signUpStep === 'complete' && (
                <SignUpComplete boxProps={boxPropsLead} />
              )}
            </>
          )}
        </Stack>
      </Flex>
    </Flex>
  );
};

export default SignUp;
