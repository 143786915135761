import { DateTimeHelper, FormatHelper } from '@waygee/common';
import {
  Currencies,
  ICreateInvoiceDto,
  IInvoiceConfigDto,
  IPayerDto,
} from '@waygee/shared-types';
import { InvoicePreview } from '../../../../../../common/components/InvoicePreview';

export type InvoiceCreatePreviewProps = {
  invoice: Partial<ICreateInvoiceDto>;
  invoiceConfig: Partial<IInvoiceConfigDto>;
  invoicePayer: Partial<IPayerDto>;
};

const InvoiceCreatePreview = ({
  invoice,
  invoiceConfig,
  invoicePayer,
}: InvoiceCreatePreviewProps) => {
  const invoicePreviewData = {
    invoiceTitle: invoiceConfig.title ?? 'My Company',
    invoiceSubTitle: invoiceConfig.subtitle ?? '',
    payerName: invoicePayer.name ?? 'My Customer',
    phone: invoiceConfig.phone ?? undefined,
    email: invoiceConfig.email ?? undefined,
    address1: invoiceConfig.address1 ?? undefined,
    address2: invoiceConfig.address2 ?? undefined,
    invoiceNumber: invoice.invoiceNumber ?? '#',
    invoiceDate: DateTimeHelper.formatLocal(
      invoice.date ?? new Date(),
      'dd/LL/yyyy'
    ),
    invoiceDueDate: invoice?.dueDate
      ? DateTimeHelper.formatLocal(invoice.dueDate, 'dd/LL/yyyy')
      : undefined,
    invoiceServiceDescription: invoice.serviceDescription ?? 'My service',
    invoiceAmount: FormatHelper.formatCurrency(
      invoice.amount ?? 0,
      invoice.currency ?? Currencies.USD
    ),
    payerAddress1: invoicePayer?.address1 ?? undefined,
    payerAddress2: invoicePayer?.address2 ?? undefined,
    payerPhone: invoicePayer?.phone ?? undefined,
    payerEmail: invoicePayer?.email ?? undefined,
    bankAccountDetailsLine1: 'Account number: 1222222',
    bankAccountDetailsLine2: 'Routing number: 1222222',
    bankAccountDetailsLine3: '',
    invoiceColor: invoiceConfig.color ?? '#9900EF',
  };
  return <InvoicePreview invoiceData={invoicePreviewData} />;
};

export default InvoiceCreatePreview;
