import handlebars from 'handlebars';
import templateConfig from './templates.config';
import { ITemplateViewTypesMap, TemplateTypes } from './templates.definitions';
import sha256 from 'crypto-js/sha256';

export class TemplatesHelper {
  private static readonly compiledTemplatesMap = new Map<
    string,
    HandlebarsTemplateDelegate
  >();

  static renderTemplate(
    templateType: TemplateTypes,
    view: ITemplateViewTypesMap[TemplateTypes]
  ) {
    const template = templateConfig[templateType];
    const compiledTemplate =
      TemplatesHelper.compileTemplate<ITemplateViewTypesMap[TemplateTypes]>(
        template
      );
    return compiledTemplate(view);
  }

  private static compileTemplate<TView>(template: string) {
    const templateKey = sha256(template).toString();
    let compiledTemplate =
      TemplatesHelper.compiledTemplatesMap.get(templateKey);
    if (!compiledTemplate) {
      compiledTemplate = handlebars.compile(template);
      TemplatesHelper.compiledTemplatesMap.set(templateKey, compiledTemplate);
    }
    return compiledTemplate as HandlebarsTemplateDelegate<TView>;
  }
}
