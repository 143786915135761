import { BoxProps, FlexProps, useColorModeValue } from '@chakra-ui/react';

type UseSignUpStylesProps = {
  isBusiness: boolean | null;
};

const useSignUpStyles = ({ isBusiness }: UseSignUpStylesProps) => {
  const flexFieldProps: FlexProps = {
    gap: '25px',
    margin: '20px',
    wrap: 'wrap',
  };

  const flexMainContainerProps: FlexProps = {
    marginTop: { base: '10px', md: isBusiness === null ? '150px' : '50px' },
    justifyContent: 'center',
    w: 'auto',
  };

  const boxProps: BoxProps = {
    rounded: 'lg',
    bg: 'white',
    boxShadow: 'lg',
    p: 8,
    minW: { base: 'unset', md: '800px' },
  };

  const boxPropsLead: BoxProps = {
    ...boxProps,
    minW: { base: 'unset', md: '400px' },
  };

  return {
    flexMainContainerProps,
    flexFieldProps,
    boxProps,
    boxPropsLead,
  };
};

export default useSignUpStyles;
