import {
  IInvoiceDefaultTemplateView,
  TemplateTypes,
  TemplatesHelper,
} from '@waygee/common';

export type InvoicePreviewProps = {
  invoiceData: IInvoiceDefaultTemplateView;
};

const InvoicePreview = ({ invoiceData }: InvoicePreviewProps) => {
  const html = TemplatesHelper.renderTemplate(
    TemplateTypes.INVOICE,
    invoiceData
  );
  return (
    <div
      dangerouslySetInnerHTML={{ __html: html }}
      style={{ width: '100%', maxHeight: '625px' }}
    />
  );
};

export default InvoicePreview;
